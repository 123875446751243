import React from "react";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';

//images
import Arrow from '../images/uploads/arrow-cyan-right-horizontal.svg'


const getRandomOrder = (arr) => {
  const clone = arr.slice()
  const ret = []
  while (clone.length > 0) {
    const obj = clone.splice(Math.floor(Math.random() * clone.length), 1)[0]
    ret.push(Object.assign({}, obj))
  }
  return ret.slice(0, 3)
}

const MoreTechnologies = (props) => {
  const moreTechs = props.moreTechs
  const randomized = getRandomOrder(moreTechs)

  const techOptions = {
    prevNextButtons: false,
    contain: true,
    pageDots: false,
    freeScroll: false
  }
  return (
    <>
      <div className="pb-6">
        <h3 className="font-poppings-bold text-center text-term-primary text-rp1 lg:text-h3">More Technologies</h3>
      </div>
      <div className="t-slider t-slideview">
        <Flickity
          className={'technology-carousel'}
          elementType={'div'}
          options={techOptions} >
          {randomized &&
            randomized.map(({ node: post }, i) => (
              <div key={i} className="t-tech-cell">
                <div className="w-full">
                  <GatsbyImage image={getImage(post.frontmatter.featured_image.image)} alt={post.frontmatter.featured_image.alt} className="w-full" />
                </div>
                <div className="mt-3-3 lg:mt-49 technologies-content">
                  <h3 className="pb-2-1 text-rp1 lg:text-h3 text-term-primary font-poppings-bold">
                    {post.frontmatter.title}
                  </h3>
                  <p className="pb-3 lg:pb-6 text-p4 lg:text-p2 font-worksans-normal text-term-primary">
                    {post.frontmatter.excerpt}
                  </p>
                  <Link to={post.fields.slug} className={`arrowhlink animate-arrow-${i} text-h6 lg:text-h5 font-poppings-bold text-cyan flex items-center tech-view-detail delay-150 duration-300 ease-in-out`} >
                    <span className="mr-1-2"> View Details </span>
                    <img src={Arrow} alt={"Arrow"} />
                  </Link>
                </div>
              </div>
            ))}
        </Flickity>
      </div>
    </>
  )
}

export default MoreTechnologies;