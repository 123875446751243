import React from "react";
import { graphql, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Flickity from 'react-flickity-component'
import MarkDown from "react-markdown"

//component
import Layout from "../components/layout";
import Seo from "../components/seo";
import LiveChat from "../components/Home/livechat";
// import FAQInsideTV from "../components/Accordion/faq-inside-tv";
import MoreTechnologies from "../components/more-technologies";
import TestimonialsText from "../components/Home/testimonials-text";

const TechnologySinglePage = ({ data, location }) => {
  const post = data.techData
  const caseStudies = data.caseStudiesData
  const SEOdata = data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: moreTechs } = data.moreTech

  const flickityOptions = {
    contain: true,
    prevNextButtons: true,
    pageDots: false,
    wrapAround: true
  }

  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Single-Technologies"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />

      {/* banner section */}
      <section className="mt-60 lg:mt-20">
        {post.frontmatter.banner_image && <BgImage image={getImage(post.frontmatter.banner_image.image)} className="technologies-inner-banner" alt={`DecodeUp Technology banner `}>
          <div className="technologies-inner-banner-overlay relative h-full">
            <div className="container mx-auto text-center h-full">
              <div className="w-full md:w-3/5 xl:w-2/5 lg:pt-87 lg:pb-1-50 h-full mx-auto flex flex-wrap lg:block items-center flex-col justify-center tech-inner-banner-content text-center relative z-60">
                <p className="mb-1-1 lg:mb-2 font-poppings-bold text-rh6 lg:text-h6 text-white">TECHNOLOGIES</p>
                <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-white">
                  {post.frontmatter.title}
                </h2>
              </div>
            </div>
          </div>
        </BgImage>
        }
      </section>

      {/* introduction section */}

      <section className="pt-50 xl:pt-86 mb-35 lg:mb-1-17">
        <div className="mx-auto container">
          <div className="mx-auto w-full xl:w-2/3 text-center">
            <h3 className="mb-2 lg:mb-2-3 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
              {post.frontmatter.title_benefits_use}
            </h3>
            <MarkDown className="px-6 text-p4 lg:text-p2 font-worksans-normal text-term-primary" children={post.frontmatter.description_benefits_use} allowDangerousHtml />
          </div>
        </div>
      </section>

      {/* benefits to use */}

      <section>
        <div className="container mx-auto">
          <div className="pb-1-10">
            <Flickity
              className={'fusion-carousel '}
              elementType={'div'}
              options={flickityOptions} >
              {post.frontmatter.benefits_of_use && post.frontmatter.benefits_of_use.map((benefit, j) => (
                <div key={j} className="fusion-cell flex flex-wrap items-center justify-between">
                  <div className="fusion-img">
                    <GatsbyImage image={getImage(benefit.image)} alt={benefit.alt} />
                  </div>
                  <div className="fusion-content">
                    <div className="">
                      <h2 className="pb-2 md:pb-4-2 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                        {benefit.title}
                      </h2>
                      <MarkDown className="pb-6-2 text-p4 lg:text-p2 text-term-primary font-worksans-normal" children={benefit.description} allowDangerousHtml />
                    </div>
                  </div>
                </div>
              ))}
            </Flickity>
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto container tech-new-sec py-50 xl:py-20">
          <div className="mx-auto w-full xl:w-2/3 text-center">
            <h3 className="mb-2 lg:mb-2-3 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
              {post.frontmatter.title_reason_use || ''}
            </h3>
            {post.frontmatter.description_reason_use && <MarkDown className="px-6 text-p4 lg:text-p2 font-worksans-normal text-term-primary" children={post.frontmatter.description_reason_use} allowDangerousHtml />}
          </div>
        </div>
      </section>



      {/* reason to use odd | even section */}

      <section>
        <div className="mx-auto container pt-44">
          {post.frontmatter.reason_use &&
            post.frontmatter.reason_use.map((data, index) => (
              <div key={index} className={`${(index % 2 === 0) ? '' : 'tech-intro-sec-bg'} `}>
                <div className={`xl:mx-1-10 py-10 md:py-50 lg:py-76 flex flex-row flex-wrap items-center`}>
                  <div className={`px-0 lg:px-1-3 py-1-2 w-full sm:w-1/2 h-full lg:h-2-68 xl:h-3-20 tech-inner-img ${(index % 2 === 0) ? '' : 'sm:order-2'}`}>
                    <GatsbyImage image={getImage(data.image)} alt={data.alt} />
                  </div>
                  <div className="px-0 sm:pl-3 md:pl-10 lg:pr-10 xl:pl-71 xl:pr-20 w-full sm:w-1/2 tech-inner-contain">
                    <h3 className="pt-2 sm:pt-0 mb-2 lg:mb-2-3 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                      {data.title}
                    </h3>
                    <MarkDown className="text-p4 lg:text-p2 font-worksans-normal text-term-primary" children={data.description} allowDangerousHtml />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>

      {post.frontmatter.extra_info &&
        <section>
          <div className="container mx-auto">
            <MarkDown className="text-p4 lg:text-p2 font-worksans-normal text-term-primary" children={post.frontmatter.extra_info} allowDangerousHtml />
          </div>
        </section>
      }


      {/* FAQs section */}
      {/* <section>
        <div className="container mx-auto">
          <div className="pt-50 xl:pt-1-20">
            <FAQInsideTV faqTitle={post.frontmatter.title} faqs={post.frontmatter.faqs} />
          </div>
        </div>
      </section> */}

      {/* related case studies component */}

      {/* <section className="mt-50 lg:mt-1-20 ">
        <div className="container mx-auto">
          <h3 className="pb-35 text-rp1 md:text-h3 font-poppings-bold text-term-primary text-center">
            Our Work in {post.frontmatter.title}
          </h3>
          <div className="h-full flex flex-wrap ">
            {caseStudies.edges && caseStudies.edges.map(({ node: post }, cs) => (
              <div key={cs} className={`px-4-1 pt-36 w-full sm:w-1/2 text-center ${cs % 2 === 0 ? 'bg-background-secondary' : 'bg-cyan'} `}>
                <Link to={post.fields.slug} className="h-full flex flex-col items-center  text-center addi-case-links">
                  <p className="pb-2-2 lg:pb-4 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    {post.frontmatter.title}
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <GatsbyImage image={getImage(post.frontmatter.additional_image.image)} className="h-full mx-auto"
                      alt="decodeup case study" />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* more technologies section */}
      <section>
        <div className="container mx-auto">
          <div className="pt-50 xl:pt-1-20 pb-50 xl:pb-88">
            <MoreTechnologies moreTechs={moreTechs} />
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto">
          <div className="pt-5 pb-53 pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 lg:pt-0 lg:pb-1-40 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
            <TestimonialsText />
          </div>
        </div>
      </section>

      {/* free scheduling section */}
      <LiveChat />

    </Layout>
  )
}

export default TechnologySinglePage;

export const query = graphql`
query SingleTechPage($techId: String!, $tech_related_cs :[String!]!) {
  techData: markdownRemark(id: { eq: $techId }) {
    id
    html
    frontmatter {
      title
      technology_detail_page_status
      title_benefits_use
      description_benefits_use
      benefits_of_use {
        alt
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
      title_reason_use
      description_reason_use
      reason_use {
        title
        description
        alt
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
      extra_info
      banner_image {
        alt
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
        overlay_image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
  moreTech: allMarkdownRemark(
    filter: { 
      frontmatter: { templateKey: { eq: "technology" } }, 
      id: { ne: $techId }
    }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featured_image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
            date(formatString: "DD MMM, YYYY")
          }
        }
      }
    }
  caseStudiesData: allMarkdownRemark(
    filter: { 
      frontmatter: {
        title: { in: $tech_related_cs, ne: "dummy" } 
        templateKey: {eq: "single-case-study"},
      }
    }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            short_details {
              bg_color_light
              bg_color_dark
            } 
          }
        }
      }
    }
    awardpagedata: allMarkdownRemark(filter: {id: {eq: $techId}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }
`;
